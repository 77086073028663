import request from '@/utils/request'

export function getTableData(data) {
  return request({
    url: '/history/getHistoryScanRecord',
    method: 'post',
    data
  })
}
export function getRedirectUrl(data) {
  return request({
    url: '/index/getRedirectUrl',
    method: 'post',
    data
  })
}
export function getLocationParams(data) {
  return request({
    url: '/index/locationParams',
    method: 'post',
    data
  })
}
export function getUserInfo(data) {
  return request({
    url: '/index/getInfo',
    method: 'post',
    data
  })
}
export function getImgUrl(data) {
  return request({
    url: '/luckyDraw/queryTurntableImagesAndSweepstakesRules',
    method: 'post',
    data,
    imgPathAddDomain: true
  })
}
export function getLocation(data) {
  return request({
    url: '/index/location',
    method: 'post',
    data
  })
}
export function getDrawRecord(data) {
  return request({
    url: '/luckyDraw/queryDrawRecord',
    method: 'post',
    data
  })
}
export function getWinningRecord(data) {
  return request({
    url: '/bonus/queryWinningRecord',
    method: 'post',
    data
  })
}
export function checkProjectConfig(data) {
  return request({
    url: '/projectCheck/checkProjectConfig',
    method: 'post',
    data
  })
}
export function codeCheck(data) {
  return request({
    url: '/projectCheck/codeCheck',
    method: 'post',
    data
  })
}
export function drawRecord(data) {
  return request({
    url: '/luckyDraw/drawRecord',
    method: 'post',
    data
  })
}
export function receiveRedPacket(data) {
  return request({
    url: '/luckyDraw/receiveRedPacketByDouMi',
    method: 'post',
    data
  })
}
export function selectPrizeUrl(data) {
  return request({
    url: '/bonus/selectPrizeUrl',
    method: 'post',
    data
  })
}
