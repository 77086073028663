const setting = require('@/setting.js')

export default function install(Vue) {
  const bgDirectiveFn = function (el, binding) {
    if (binding.value) {
      let obj =
        typeof binding.value == 'string'
          ? {
              remoteSrc: binding.value
            }
          : binding.value
      let imgSrc = setting.loadRemotePic ? obj.remoteSrc : obj.localSrc
      if (el.tagName == 'IMG') {
        el.src = imgSrc
      } else {
        el.style.backgroundImage = `url(${imgSrc})`
      }
    }
  }
  Vue.directive('bg', {
    bind: bgDirectiveFn,
    update: bgDirectiveFn
  })
}
